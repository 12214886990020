import { ApplicationConfig } from '@/config/applicationConfig.ts';
import useMessageRotator from '@/hooks/useLoaderMessageList.ts';
import { Button, Col, Row } from 'react-bootstrap';

interface MobileChatBotProps {
    serviceId: string;
}

export const MobileChatBot = ({ serviceId }: MobileChatBotProps) => {
    const messageList = ['Unlock full ability by installation 😎'];

    const currentMessage = useMessageRotator(messageList, 5_000);

    return (
        <>
            <Row style={{ width: '100vw', height: '8vh', display: 'flex', alignItems: 'center' }}>
                <Col className="mx-auto p-0" xs={9} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {currentMessage}
                </Col>
                <Col className="p-0" xs={3}>
                    <Button size="sm">Try Garam</Button>
                </Col>
            </Row>
            <div style={{ width: '100vw', height: '92vh' }}>
                <iframe width="100%" height="100%" src={`${ApplicationConfig.chatbotURL}/?serviceId=${serviceId}&show=true&mode=playground&disableClose=true`}></iframe>
            </div>
        </>
    );
};
