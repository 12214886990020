import { useTranslation } from 'react-i18next';

export const AgentScriptIntroduction = () => {
    const { t } = useTranslation();

    return (
        <div className="mb-2">
            <h5>{t('TellGaramNeedsToKeepInMind')}</h5>
            {t('AgentScriptIsTheScriptGaramAlwaysKeepInind')}
            {t('AskEmailAddressWheneverCustomerReachutToYou')}
        </div>
    );
};
