import { useMutation } from '@tanstack/react-query';
import { ServiceToRegister } from '@/model/ServiceToRegister.ts';
import { Initiate } from '@/model/Initiate.ts';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useNotificationContext } from '@/common';
import { registerSchema } from '@/pages/register/useRegister.ts';

export function useDemoRegister() {
    const serviceClient = ClientFactory.createGaramServiceClient();
    const navigate = useNavigate();
    const { showNotification } = useNotificationContext();

    async function initiateService(data: ServiceToRegister) {
        const initiateServiceRequest: Initiate = { ...data, userId: uuidv4(), applicationName: data.agentName, description: data.serviceDescription };

        return serviceClient.initiateDemoService({
            ...initiateServiceRequest,
            serviceType: 'DemoStore',
        });
    }

    const { mutate: register, isPending } = useMutation({
        mutationFn: async (data: ServiceToRegister) => initiateService(data),
        onSuccess: (data) => {
            navigate(`/demo/chatBot?serviceId=${data.service.id}`);
        },
        onError: () => {
            showNotification({
                title: 'Error',
                message: 'Registration fail.',
                type: 'error',
            });
        },
    });

    return { isPending, register, schema: registerSchema, errors: {} };
}

//demo/chatBot?serviceId=9db6169c-33b1-44ee-9b57-93146bec3c58