import { GetKnowledgeBaseResponse } from '@/model/KnowledgeBase';
import { KnowledgeBase } from '@/pages/refactorChatbot/tabs/QuestionAndAnswer';
import moment from 'moment';

export const getKnowledgeBaseMapper = (res: GetKnowledgeBaseResponse): KnowledgeBase[] => {
    return res.map((item) => {
        return {
            id: item.id,
            question: item.context.question,
            answer: item.context.answer,
            createdDate: moment(new Date()).fromNow(),
        };
    });
};
