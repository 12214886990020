import { Button, Form } from 'react-bootstrap';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Loader, PageSize, Table } from '@/components';
import { Column } from 'react-table';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { useNotificationContext } from '@/common';
import { useTranslation } from 'react-i18next';
import { DefaultIdIndex, useAppContext } from '@/context/App.context';
import { useWebSitePage } from './component/useWebSitePage';

type WebSite = {
    id: string;
    url: string;
    createdDate: string; // CHECK 이 데이터가 필요한 것 인가
};

const columns: ReadonlyArray<Column> = [
    {
        Header: 'URL',
        accessor: 'url',
        defaultCanSort: true,
    },
];

const sizePerPageList: PageSize[] = [
    {
        text: '5',
        value: 5,
    },
    {
        text: '10',
        value: 10,
    },
    {
        text: '25',
        value: 25,
    },
    {
        text: 'All',
        value: 3,
    },
];

export enum EDIT_TYPE {
    CREATE,
    EDIT,
}

const CustomRightSideBar = React.lazy(() => import('../../../layouts/CustomRightSidebar'));

export const WebPage = () => {
    const { t } = useTranslation();
    const [webSites, setWebSites] = useState<WebSite[]>([]);
    const [selectedRows, setSelectedRows] = useState<WebSite[]>([]);
    const [editor, setEditor] = useState<{
        show: boolean;
        type: EDIT_TYPE;
    }>({
        show: false,
        type: EDIT_TYPE.CREATE,
    });
    const { showNotification } = useNotificationContext();
    const [url, setUrl] = useState<string>('');
    const { serviceIds } = useAppContext();
    const { addDataSource, deleteDataSource, getDataSources } = useWebSitePage(serviceIds.applicationIds[DefaultIdIndex]);

    const { mutate: addWebSite } = useMutation({
        mutationFn: () => addDataSource(url),
        onSuccess: () => {
            refetch();
            setUrl('');
            setEditor((prev) => {
                return { ...prev, show: false };
            });
            showNotification({
                title: 'Success',
                message: t('MESSAGE_CREATE_WEB_SITE_SUCCESS'),
                type: 'success',
            });
        },
        onError: () => {
            showNotification({
                title: 'Error',
                message: t('MESSAGE_CREATE_WEB_SITE_FAIL'),
                type: 'error',
            });
        },
    });

    const { mutate: deleteWebSite, isPending: isPendingDelete } = useMutation({
        mutationFn: (dataSourceId: string) => deleteDataSource(dataSourceId),
        onSuccess: () => {
            refetch();
            setEditor((prev) => {
                return { ...prev, show: false };
            });
            setUrl('');
            showNotification({
                title: 'Success',
                message: t('MESSAGE_DELETE_WEB_SITE_SUCCESS'),
                type: 'success',
            });
        },
        onError: () => {
            showNotification({
                title: 'Error',
                message: t('MESSAGE_DELETE_WEB_SITE_FAIL'),
                type: 'error',
            });
        },
    });

    const { data, refetch } = useSuspenseQuery({
        queryKey: ['dataSource', 'Get'],
        queryFn: () => getDataSources(),
        staleTime: 0,
    });

    useEffect(() => {
        if (data) {
            setWebSites(
                data.map(({ id, url }) => {
                    return {
                        id,
                        url,
                        createdDate: new Date().toISOString(),
                    };
                }),
            );
        }
    }, [data]);

    const updateSelectedRows = useCallback((rows: WebSite[]) => {
        setSelectedRows(rows);
    }, []);

    return (
        <div className="d-flex flex-column h-100">
            <div className="flex-grow-1">
                <Table<WebSite>
                    columns={columns}
                    data={webSites}
                    pageSize={5}
                    sizePerPageList={sizePerPageList}
                    isSortable={true}
                    pagination={true}
                    isSelectable={true}
                    onChangeSelectRows={updateSelectedRows}
                />
            </div>
            <div className="ms-auto d-flex gap-2 mt-4">
                <Button
                    variant={'outline-primary'}
                    onClick={() => {
                        setEditor((prev) => {
                            return { ...prev, show: true };
                        });
                    }}
                >
                    <i className="uil-plus pe-1"></i>
                    Add
                </Button>
                <Button
                    variant={'primary'}
                    disabled={selectedRows.length < 1 || isPendingDelete}
                    onClick={() => {
                        selectedRows.forEach(({ id }) => {
                            deleteWebSite(id);
                        });
                    }}
                >
                    Delete
                </Button>
            </div>
            <Suspense fallback={<Loader />}>
                <CustomRightSideBar
                    title={'Enter Web Site'}
                    show={editor.show}
                    handleRightSideBar={() => {
                        setEditor((prev) => {
                            return {
                                ...prev,
                                show: false,
                            };
                        });
                    }}
                    buttonName="Add web site"
                    onClickSubmit={() => {
                        addWebSite();
                    }}
                >
                    <>
                        <Form>
                            <Form.Group>
                                <Form.Label>URL</Form.Label>
                                <Form.Control type="url" placeholder="https://www.google.com" value={url} onChange={(e) => setUrl(e.target.value)} />
                            </Form.Group>
                        </Form>
                    </>
                </CustomRightSideBar>
            </Suspense>
        </div>
    );
};
