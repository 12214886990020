import { Col, Container, Row } from 'react-bootstrap';
import { ApplicationConfig } from '@/config/applicationConfig.ts';

interface PCDemoChatBotProps {
    serviceId: string;
}

export const PCDemoChatBot = ({ serviceId }: PCDemoChatBotProps) => {
    return (
        <Container>
            <Row style={{ width: '100vw', height: '100vh' }}>
                <Col lg={2} xs={0} className="d-none d-lg-block"></Col>
                <Col lg={5} xs={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '400px', minWidth: '400px' }}>
                    <div style={{ width: '400px', height: '700px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                        <iframe width="100%" height="100%" src={`${ApplicationConfig.chatbotURL}/?serviceId=${serviceId}&show=true&mode=playground&disableClose=true`}></iframe>
                    </div>
                </Col>
                <Col lg={5} xs={7} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'left' }}>
                    <div>
                        <h1 style={{ marginBottom: '1rem' }}>Welcome to Garam! 🎉</h1>
                    </div>
                    <div>
                        <h4>{`This is demo page can have lower performance than normal since Garam doesn't have permission to access your store product`}</h4>
                    </div>
                    <div>
                        <h3>{`Garam is helping your business by:`}</h3>
                        <h4>{`- 💁 Garam becomes your virtual shopping representative.`}</h4>
                        <h4>{`- 🔎 Garam helps customer easily find products and recommend`}</h4>
                        <h4>{`- 💬 Garam supports LiveChat easily intervene to your customer in anytime`}</h4>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
