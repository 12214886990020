import React from 'react';
import './MobileMessage.scss';

import googlePlayLogo from '@/assets/images/mobile/google-play-logo.png';
import appStoreLogo from '@/assets/images/mobile/app-store-logo.png';
import garamAppLogo from '@/assets/images/mobile/garam-app-logo.png';

const googlePlayLink = "UNDER_DEVELOPMENT";
const appStoreLink = "https://apps.apple.com/in/app/garam-livechat-chatbot/id6689512365";

const MobileMessage: React.FC = () => {
  return (
    <div className="mobile-message">
      <h2>Install Garam admin app!</h2>
      <div className="py-6">
        <img src={garamAppLogo} alt="Garam App" />
      </div>
      <h4>Download Mobile App</h4>
      <div className="store-links">
        <a href={googlePlayLink} target="_blank" rel="noopener noreferrer" className="disabled-link">
          <img src={googlePlayLogo} alt="Google Play" />
        </a>
        <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
          <img src={appStoreLogo} alt="App Store" />
        </a>
      </div>
      <p className="py-2">We are preparing Android App!</p>
    </div>
  );
};

export default MobileMessage;
