import { Button, Col, Form, Row } from 'react-bootstrap';
import { ChangeEvent } from 'react';
import { TimeWindow } from '@/model/Application';
import { useOperationTimeslots } from './useOperationTimeslots.ts';

export interface OperationTimeSlotProps {
    operationTimeSlot: TimeWindow;
    setOperationTimeSlot: (timeWindow: TimeWindow) => void;
    deleteOperationTimeSlot: () => void;
}

export const OperationTimeslot = ({ operationTimeSlot, setOperationTimeSlot, deleteOperationTimeSlot }: OperationTimeSlotProps) => {
    const { validateTimeWindow } = useOperationTimeslots();
    function handleStartAtChange(event: ChangeEvent<HTMLInputElement>): void {
        const normalizedTimeslot = normalizeTimeslot(operationTimeSlot);
        const [hours, minutes] = event.target.value.split(':').map(Number);
        const newDate = new Date();
        newDate.setHours(hours, minutes, 0, 0);

        setOperationTimeSlot({
            ...normalizedTimeslot,
            startAt: newDate,
        });
    }

    function handleEndAtChange(event: ChangeEvent<HTMLInputElement>): void {
        const normalizedTimeslot = normalizeTimeslot(operationTimeSlot);
        const [hours, minutes] = event.target.value.split(':').map(Number);
        const newDate = new Date();
        newDate.setHours(hours, minutes, 0, 0);

        setOperationTimeSlot({
            ...normalizedTimeslot,
            endAt: newDate,
        });
    }

    function normalizeTimeslot(timeSlot: TimeWindow): TimeWindow {
        const normalizedStartAt: Date | undefined = timeSlot.startAt ? new Date() : undefined;
        const normalizedEndAt: Date | undefined = timeSlot.endAt ? new Date() : undefined;

        if (normalizedStartAt) {
            normalizedStartAt.setHours(timeSlot.startAt!.getHours(), timeSlot.startAt!.getMinutes(), 0, 0);
        }

        if (normalizedEndAt) {
            normalizedEndAt.setHours(timeSlot.endAt!.getHours(), timeSlot.endAt!.getMinutes(), 0, 0);
        }

        return {
            startAt: normalizedStartAt,
            endAt: normalizedEndAt,
        };
    }

    function formatTime(date?: Date): string {
        if (!date) {
            return '--:--';
        }

        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    }

    function getBorderStyle(): string {
        return validateTimeWindow(operationTimeSlot) ? '' : 'border-danger border border-2 border-opacity-50 rounded-3';
    }

    return (
        <Row className="align-items-center mb-3">
            <Col lg={5}>
                <div className={`d-inline-block w-auto p-1 ${getBorderStyle()}`}>
                    <div className="d-inline-block w-auto">
                        <Form.Control type="time" value={formatTime(operationTimeSlot.startAt)} onChange={handleStartAtChange} />
                    </div>
                    <div className="d-inline-block text-center w-auto mx-2">
                        <div style={{ fontSize: '1.2rem' }}>~</div>
                    </div>
                    <div className="d-inline-block w-auto">
                        <Form.Control type="time" value={formatTime(operationTimeSlot.endAt)} onChange={handleEndAtChange} />
                    </div>
                </div>
                <div className="d-inline-block w-auto">
                    <Button className="fw-bold" variant="none" onClick={() => deleteOperationTimeSlot()}>
                        X
                    </Button>
                </div>
            </Col>
        </Row>
    );
};
4;
