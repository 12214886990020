import { useTranslation } from 'react-i18next';

export const KnowledgeBaseInstruction = () => {
    const { t } = useTranslation();

    return (
        <div>
            <h5>{t('AddTheKnowledgeBaseThatGaramNeeds')}</h5>
            {t('GaramSearchesTheKnowledgeBase')}
        </div>
    );
};
