import { OperationTimeslot } from './OperationTimeslot.tsx';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useOperationTimeslots } from './useOperationTimeslots.ts';
import { TimeWindow } from '@/model/Application';

export interface OperationTimeslotsProps {
    operationTimeslots: TimeWindow[];
    onChange: (operationTimeslots: TimeWindow[]) => void;
}

export const OperationTimeslots = ({ operationTimeslots, onChange }: OperationTimeslotsProps) => {
    const { t } = useTranslation();
    const { changeOperationTimeSlot, deleteOperationTimeSlot, addOperationTimeSlot } = useOperationTimeslots();

    function onChangeOperationTimeSlot(index: number, timeWindow: TimeWindow): void {
        onChange(changeOperationTimeSlot(operationTimeslots, index, timeWindow));
    }

    function onDeleteOperationTimeSlot(index: number): void {
        onChange(deleteOperationTimeSlot(operationTimeslots, index));
    }

    function onAddOperationTimeSlot(): void {
        onChange(addOperationTimeSlot(operationTimeslots));
    }

    return (
        <div>
            {operationTimeslots.map((operationTimeSlot, index) => (
                <OperationTimeslot
                    key={index}
                    operationTimeSlot={operationTimeSlot}
                    setOperationTimeSlot={(newTimeslot: TimeWindow) => onChangeOperationTimeSlot(index, newTimeslot)}
                    deleteOperationTimeSlot={() => onDeleteOperationTimeSlot(index)}
                />
            ))}
            <Button variant={'outline-primary'} onClick={() => onAddOperationTimeSlot()}>
                <i className="uil-plus pe-1"></i> {t('Add Timeslot')}
            </Button>
        </div>
    );
};
