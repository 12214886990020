import { Button, Stack, Form, Pagination } from 'react-bootstrap';
import { Loader } from '@/components';
import React, { Suspense, useCallback, useState, useMemo } from 'react';
import SimpleMDEReact, { SimpleMDEReactProps } from 'react-simplemde-editor';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNotificationContext } from '@/common/context/useNotificationContext';
import { DefaultIdIndex, useAppContext } from '@/context/App.context';
import { KnowledgeBaseInstruction } from './component/KnowledgeBaseInstruction.tsx';

export type KnowledgeBase = {
    id: string;
    question: string;
    answer: string;
    createdDate: string;
};

export enum EDIT_TYPE {
    CREATE,
    EDIT,
}
interface RoundedPaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (newPage: number) => void;
}
const CustomRightSideBar = React.lazy(() => import('../../../layouts/CustomRightSidebar'));
const delay = 1000;
const options: SimpleMDEReactProps['options'] = {
    toolbar: [],
    autosave: {
        enabled: true,
        uniqueId: '1',
        delay,
    },
};
const RoundedPagination = ({ currentPage, totalPages, onPageChange }: RoundedPaginationProps) => {
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => onPageChange(number)}>
                {number}
            </Pagination.Item>,
        );
    }

    return (
        <Pagination className="pagination pagination-rounded d-inline-flex ms-auto align-item-center mb-0">
            <Pagination.Prev onClick={() => currentPage > 1 && onPageChange(currentPage - 1)} disabled={currentPage === 1} />
            {items}
            <Pagination.Next onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        </Pagination>
    );
};
export const QuestionAndAnswer = () => {
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();

    const [selectedRows, setSelectedRows] = useState<KnowledgeBase[]>([]);
    const [editor, setEditor] = useState<{
        show: boolean;
        type: EDIT_TYPE;
    }>({
        show: false,
        type: EDIT_TYPE.CREATE,
    });
    const [knowledgeBase, setKnowledgeBase] = useState<Pick<KnowledgeBase, 'question' | 'answer'>>({
        question: '',
        answer: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const serviceClient = ClientFactory.createGaramServiceClient();
    const { serviceIds } = useAppContext();

    const { data: knowledgeBases = [], refetch } = useSuspenseQuery({
        queryKey: ['knowledgeBase', 'Get'],
        queryFn: () => serviceClient.getKnowledgeBase(serviceIds.applicationIds[DefaultIdIndex]),
        staleTime: 0,
    });

    const { mutate: createKnowledgeBase } = useMutation({
        mutationFn: () => serviceClient.createKnowledgeBase(serviceIds.applicationIds[DefaultIdIndex], knowledgeBase),
        onSuccess: () => {
            showNotification({
                title: 'Success',
                message: t('MESSAGE_CREATE_KNOWLEDGE_BASE_SUCCESS'),
                type: 'success',
            });
            refetch();
        },
        onError: () => {
            showNotification({
                title: 'Error',
                message: t('MESSAGE_CREATE_KNOWLEDGE_BASE_FAIL'),
                type: 'error',
            });
        },
    });

    const deleteKnowledgeBases = useCallback(async () => {
        for (const row of selectedRows) {
            await serviceClient.deleteKnowledgeBase(serviceIds.applicationIds[DefaultIdIndex], row.id);
        }
    }, [selectedRows, serviceClient, serviceIds.applicationIds]);

    const { mutate: deleteKnowledgeBase } = useMutation({
        mutationFn: deleteKnowledgeBases,
        onSuccess: () => {
            showNotification({
                title: 'Success',
                message: t('MESSAGE_DELETE_KNOWLEDGE_BASE_SUCCESS'),
                type: 'success',
            });
            refetch();
        },
        onError: () => {
            showNotification({
                title: 'Error',
                message: t('MESSAGE_DELETE_KNOWLEDGE_BASE_FAIL'),
                type: 'error',
            });
        },
    });

    const { mutate: updateKnowledgeBase } = useMutation({
        mutationFn: () =>
            serviceClient.updateKnowledgeBase(serviceIds.applicationIds[DefaultIdIndex], {
                id: selectedRows[0].id,
                ...knowledgeBase,
            }),
        onSuccess: () => {
            showNotification({
                title: 'Success',
                message: t('MESSAGE_DELETE_KNOWLEDGE_BASE_SUCCESS'),
                type: 'success',
            });
            refetch();
        },
        onError: () => {
            showNotification({
                title: 'Error',
                message: t('MESSAGE_DELETE_KNOWLEDGE_BASE_FAIL'),
                type: 'error',
            });
        },
    });

    const setShowEditor = useCallback((show: boolean) => {
        setEditor((prev) => {
            return { ...prev, show };
        });
    }, []);

    const paginatedData = knowledgeBases.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    const totalPages = useMemo(() => {
        return Math.ceil(knowledgeBases.length / pageSize);
    }, [knowledgeBases.length, pageSize]);

    const handlePageChange = useCallback((newPage: number) => {
        setCurrentPage(newPage);
    }, []);
    const handleAddButtonClick = useCallback(() => {
        setEditor({
            show: true,
            type: EDIT_TYPE.CREATE,
        });
        setKnowledgeBase({
            question: '',
            answer: '',
        });
    }, []);
    const handleModifyButtonClick = useCallback(() => {
        setEditor({
            show: true,
            type: EDIT_TYPE.EDIT,
        });
        const [{ question, answer }] = selectedRows;
        setKnowledgeBase({ question, answer });
    }, [selectedRows]);

    const handleDeleteButtonClick = useCallback(() => {
        deleteKnowledgeBase();
    }, [deleteKnowledgeBase]);
    return (
        <>
            <KnowledgeBaseInstruction />
            <hr />
            <table className="table table-hover table-borderless">
                <tbody>
                    {paginatedData.map((item) => (
                        <tr key={item.id}>
                            <td style={{ width: '5%' }}>
                                <Form.Check
                                    type="checkbox"
                                    checked={selectedRows.some((row) => row.id === item.id)}
                                    onChange={() => {
                                        const updatedRows = selectedRows.some((row) => row.id === item.id)
                                            ? selectedRows.filter((row) => row.id !== item.id)
                                            : [...selectedRows, item];
                                        setSelectedRows(updatedRows);
                                    }}
                                />
                            </td>
                            <td>
                                <p className="mb-1">
                                    <strong>Q: {item.question}</strong>
                                </p>
                                <p className="mb-0">{item.answer}</p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="d-lg-flex align-items-center text-center pb-1">
                <div className="d-inline-block me-3">
                    <label className="me-1">Display :</label>
                    <Form.Select
                        size="sm"
                        style={{ width: 'auto', display: 'inline-block', marginLeft: '5px' }}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                            setCurrentPage(1);
                        }}
                    >
                        {[5, 10, 25, 50].map((size) => (
                            <option key={size} value={size}>
                                {size}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <span className="me-3">
                    Page &nbsp;
                    <strong>
                        {currentPage} of {totalPages}
                    </strong>
                </span>
                <Form.Group controlId="goToPage" className="d-inline-flex align-items-center justify-content-end ms-auto  my-sm-0 my-2">
                    <Form.Label className="form-label mb-0 text-nowrap">Go to page:</Form.Label>
                    <Form.Control
                        className="form-control ms-2 form-control-sm"
                        type="number"
                        value={currentPage}
                        min={1}
                        max={totalPages}
                        onChange={(e) => handlePageChange(Number(e.target.value))}
                    />
                </Form.Group>
                <RoundedPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            </div>
            <Stack direction="horizontal" gap={1}>
                <div className="ms-auto d-flex gap-2 mt-4">
                    <Button variant={'outline-primary'} onClick={handleAddButtonClick}>
                        <i className="uil-plus pe-1"></i>
                        Add
                    </Button>
                    <Button variant={'primary'} className="btn-primary-alt" disabled={selectedRows.length !== 1} onClick={handleModifyButtonClick}>
                        Modify
                    </Button>
                    <Button variant={'primary'} disabled={selectedRows.length < 1} onClick={handleDeleteButtonClick}>
                        Delete
                    </Button>
                </div>
            </Stack>
            <Suspense fallback={<Loader />}>
                <CustomRightSideBar
                    title={'Enter Knowledge Base'}
                    show={editor.show}
                    handleRightSideBar={() => setShowEditor(false)}
                    buttonName="Add knowledge base"
                    onClickSubmit={() => {
                        if (editor.type === EDIT_TYPE.CREATE) {
                            createKnowledgeBase();
                        } else {
                            updateKnowledgeBase();
                        }
                        setShowEditor(false);
                    }}
                >
                    <>
                        <Form>
                            <Form.Label>Question</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="I would like to request a refund."
                                value={knowledgeBase.question}
                                onChange={(e) =>
                                    setKnowledgeBase((prev) => {
                                        return { ...prev, question: e.target.value };
                                    })
                                }
                            />
                            <label className="form-label">Answer</label>
                            <SimpleMDEReact
                                options={options}
                                value={knowledgeBase.answer}
                                onChange={(value) => {
                                    setKnowledgeBase((prev) => {
                                        return { ...prev, answer: value };
                                    });
                                }}
                            />
                        </Form>
                    </>
                </CustomRightSideBar>
            </Suspense>
        </>
    );
};
