import { useAppContext } from '@/context/App.context.tsx';
import { useCallback } from 'react';
import ProtectedRoutes from '@/routes/ProtectedRoutes.tsx';
import { Loader } from '@/components';
import { withUserLogin } from '@/components/Auth/authenticatorHOC.tsx';

const AuthorizedApp = () => {
    const { isLoading, user, refetch } = useAppContext();

    const render = useCallback(() => {
        if (isLoading) {
            return;
        }
        if (user.id === '') {
            refetch();
            return;
        }

        return <ProtectedRoutes />;
    }, [isLoading, refetch, user.id]);

    return (
        <>
            {isLoading && <Loader />}
            {render()}
        </>
    );
};

export default withUserLogin(AuthorizedApp);
