import { Button, Form } from 'react-bootstrap';
import './SetUpService.css';
import { Dispatch, SetStateAction, useState } from 'react';
import { ServiceToRegister } from '@/model/ServiceToRegister';
import { ClientFactory } from '@/client/ClientFactory.ts';

interface ShoppingMallDomainInputProps {
    serviceToRegister: Partial<ServiceToRegister>;
    setServiceToRegister: Dispatch<SetStateAction<Partial<ServiceToRegister>>>;
}

export default function ShoppingMallDomainInput({ serviceToRegister, setServiceToRegister }: ShoppingMallDomainInputProps) {
    const facadeServiceClient = ClientFactory.createGaramServiceClient();
    const [domainUrl, setDomainUrl] = useState(serviceToRegister.siteDomain ?? '');
    const [error, setError] = useState('');

    function setShoppingMallHomePageURL(homePageURL: string) {
        const strippedUrl = homePageURL.replace(/^https?:\/\//, '').replace(/\/$/, '');

        setDomainUrl(strippedUrl);
    }

    async function handleSubmit(e: React.FormEvent): Promise<void> {
        e.preventDefault();
        if (!(await facadeServiceClient.validateUrl(domainUrl))) {
            setError('Invalid URL. Please check the URL and try again.');
            return;
        }

        setServiceToRegister((prev) => ({
            ...prev,
            siteDomain: domainUrl,
        }));
    }

    return (
        <Form className="shopping-mall-url-form">
            <Form.Group className="mb-3" controlId="ShoppingMallURL">
                <Form.Label>Shopping mall domain</Form.Label>
                <Form.Control
                    type="url"
                    placeholder="Website should support https://"
                    className="url-input"
                    value={domainUrl}
                    isInvalid={!!error}
                    onChange={(e) => setShoppingMallHomePageURL(e.target.value)}
                />
                {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
                <small>Garam only supports https</small>
            </Form.Group>
            <Button className="button-container" variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
                {"Let's go!"}
            </Button>
        </Form>
    );
}
