import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export function InstallationGuideCard() {
    const navigate = useNavigate();

    return (
        <Card>
            <Card.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <h3>{`Garam AI Integration Guide`}</h3>
                <i className="ri-close-circle-line font-22"></i>
            </Card.Header>
            <Card.Body>
                <h4 onClick={() => navigate('/settings/installation')} style={{ cursor: 'pointer' }}>
                    {`Go Guide Page `}
                    <i className="ri-arrow-right-circle-line font-18"></i>
                </h4>
            </Card.Body>
        </Card>
    );
}
