import { Col } from 'react-bootstrap';
import Conversations from './ConversationList.tsx';
import useConversations from './hooks/useConversations.ts';
import React from 'react';
import ConversationArea from './ConversationArea.tsx';
import { AdditionalInfo } from './ConversationAdditiionalInfo.tsx';
import AccordionList from './ConversationFilter.tsx';
import { ConversationStatus } from './types.ts';
import './inbox.css';

const ConversationsApp = () => {
    const { conversations, selectedIndex, isLoading, hasMore, selectedConversation, refetch, updateSelectedIndex, updateSelectedStatus, updateConversation } = useConversations();

    const items = [{ title: 'Status', content: ['Open', 'Closed'] }];

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 80px)' }}>
                <Col style={{ flex: '0.8 0 0%', minWidth: 200, maxWidth: 200 }}>
                    <AccordionList
                        items={items}
                        onClickItem={(item) => {
                            updateSelectedStatus(item as ConversationStatus);
                        }}
                    />
                </Col>
                <Col style={{ minWidth: 300, maxWidth: 300 }}>
                    <Conversations
                        isLoadingConversation={isLoading}
                        selectedIndex={selectedIndex}
                        conversations={conversations}
                        hasMore={hasMore}
                        onClickConversation={updateSelectedIndex}
                        refetch={refetch}
                    />
                </Col>
                <Col style={{ flex: '1 1 200px', minWidth: 350 }}>
                    {selectedConversation ? <ConversationArea selectedConversation={selectedConversation} updateConversation={updateConversation} /> : <></>}
                </Col>
                <Col style={{ minWidth: 360, maxWidth: 360 }}>{selectedConversation && <AdditionalInfo customerId={selectedConversation.customerId}></AdditionalInfo>}</Col>
            </div>
        </>
    );
};

export default React.memo(ConversationsApp);
