export class URLParamReader {
    private static readonly SHOPPING_MALL_DOMAIN_PARAM = 'shop';
    private static readonly MODE_PARAM = 'mode';
    private static readonly SERVICE_ID_PARAM = 'serviceId';

    private constructor() {}

    public static getShoppingMallDomainFromParam(): string | undefined {
        const shoppingMallDomain = new URLSearchParams(location.search).get(URLParamReader.SHOPPING_MALL_DOMAIN_PARAM);

        if (shoppingMallDomain) {
            return shoppingMallDomain;
        }

        return undefined;
    }

    public static isDemoMode(): boolean {
        if (location.pathname.includes('demo')) {
            return true;
        }

        const mode = new URLSearchParams(location.search).get(URLParamReader.MODE_PARAM);

        return mode === 'demo';
    }

    public static getServiceIdFromParam(): string | undefined {
        const serviceId = new URLSearchParams(location.search).get(URLParamReader.SERVICE_ID_PARAM);

        if (serviceId) {
            return serviceId;
        }

        return undefined;
    }

    public static appendHttpProtocolIfMissing(domain: string): string {
        if (domain.startsWith('http://') || domain.startsWith('https://')) {
            return domain;
        }

        return `https://${domain}`;
    }
}
