import { URLParamReader } from '@/client/browser/URLParamReader.ts';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { DeviceAnalyzer } from '@/client/device/DeviceAnalyzer.ts';
import { MobileChatBot } from '@/pages/demo/MobileChatBot.tsx';
import { PCDemoChatBot } from '@/pages/demo/PCDemoChatBot.tsx';

export const DemoChatBot = () => {
    const serviceId = URLParamReader.getServiceIdFromParam();
    const navigate = useNavigate();

    useEffect(() => {
        if (!serviceId) {
            navigate('/demo/register');
        }
    }, []);

    return serviceId && <>{new DeviceAnalyzer().isMobile() ? <MobileChatBot serviceId={serviceId} /> : <PCDemoChatBot serviceId={serviceId} />}</>;
};
