import { ClientFactory } from '@/client/ClientFactory.ts';
import { DefaultIdIndex } from '@/context/App.context';
import { AddDataSourceResponse, GetDataSourceResponse } from '@/model/DataSource.ts';

export function useWebSitePage(applicationId: string) {
    const serviceClient = ClientFactory.createGaramServiceClient();

    async function getCollectionId(): Promise<string> {
        const collections = await serviceClient.getCollections(applicationId);

        return collections[DefaultIdIndex].collectionId; // TODO 현재는 0번만 지원함
    }

    async function addDataSource(url: string): Promise<AddDataSourceResponse> {
        return serviceClient.addDataSource(applicationId, await getCollectionId(), {
            depth: 3,
            htmlElements: [],
            type: 'WebPage',
            url,
        });
    }

    async function deleteDataSource(dataSourceId: string): Promise<boolean> {
        return serviceClient.deleteDataSources(applicationId, await getCollectionId(), dataSourceId);
    }

    async function getDataSources(): Promise<GetDataSourceResponse> {
        return serviceClient.getDataSources(applicationId, await getCollectionId());
    }

    return { addDataSource, deleteDataSource, getDataSources: getDataSources };
}
