import './SetUpService.css';
import { Dispatch, SetStateAction, useEffect } from 'react';
import ShoppingMallDomainInput from '@/pages/register/setup/ShoppingMallDomainInput.tsx';
import { useSetUp } from '@/pages/register/setup/useSetUp.ts';
import { useQuery } from '@tanstack/react-query';
import { ServiceToRegister } from '@/model/ServiceToRegister';
import { Loader } from '@/components';
import useMessageRotator from '@/hooks/useLoaderMessageList';
import { URLParamReader } from '@/client/browser/URLParamReader.ts';

interface SetUpServiceProps {
    serviceToRegister: Partial<ServiceToRegister>;
    setServiceToRegister: Dispatch<SetStateAction<Partial<ServiceToRegister>>>;
}

export default function SetUpService({ serviceToRegister, setServiceToRegister }: SetUpServiceProps) {
    const isDemoMode = URLParamReader.isDemoMode();
    const { autoSetUpService } = useSetUp(serviceToRegister, setServiceToRegister);

    const { isLoading, refetch } = useQuery({
        queryKey: ['autoSetUpService', serviceToRegister.siteDomain],
        queryFn: autoSetUpService,
    });

    const messageList = ['Garam is leaning about your shopping mall. 🔍', 'Service is almost ready to use. 😎', 'Hold it tight! Garam will be ready soon 😁'];
    const currentMessage = useMessageRotator(messageList, 3000);

    useEffect(() => {
        if (serviceToRegister.siteDomain) {
            refetch();
        }
    }, [serviceToRegister.siteDomain]);

    if (!isLoading && !serviceToRegister.siteDomain) {
        return (
            <div className="form-container">
                {isDemoMode && (
                    <div className="mb-2" style={{ textAlign: 'center' }}>
                        <h2>Test with your shopping mall 🛍️</h2>
                        <h5>No sign up, just need your shopping mall address</h5>
                    </div>
                )}
                <ShoppingMallDomainInput serviceToRegister={serviceToRegister} setServiceToRegister={setServiceToRegister} />
            </div>
        );
    }

    return <Loader message={currentMessage} />;
}
