import { Route, Routes as ReactRoutes, useNavigate } from 'react-router-dom';
import ErrorPages from '@/pages/error';
import ErrorPageNotFound from '@/pages/error/PageNotFound';
import Register from '@/pages/register';
import { useAppContext } from '@/context/App.context.tsx';
import { useEffect } from 'react';
import AuthorizedApp from '@/AuthorizedApp.tsx';
import DemoRoutes from '@/routes/DemoRoutes.tsx';
import { ClientFactory } from '@/client/ClientFactory';

export default function AppRoutes() {
    const { isRegister, user } = useAppContext();
    const navigate = useNavigate();
    useEffect(() => {
        const handleUnload = async () => {
            await ClientFactory.createInboxClient().disconnect();
        };
        window.addEventListener('unload', handleUnload);
        return () => {
            window.removeEventListener('unload', handleUnload);
        };
    }, []);

    useEffect(() => {
        if (!isRegister && user.email !== '') {
            navigate(`/register${location.search}`);
        }
    }, [isRegister, navigate, user.email]);

    return (
        <ReactRoutes>
            <Route path="/demo/*" element={<DemoRoutes />} />
            <Route path="/register" element={<Register />} />
            <Route path="/*" element={<AuthorizedApp />} />
            <Route path="/error/*" element={<ErrorPages />} />
            <Route path="*" element={<ErrorPageNotFound />} />
        </ReactRoutes>
    );
}
