import Clarity from '@microsoft/clarity';
import { ApplicationConfig } from '@/config/applicationConfig.ts';
import { Stage } from '@/config/Stage.ts';

export class ClarityInitializer {
    private static readonly ClarityProjectId: string = 'pgoezh4xhi';

    public static initClarity() {
        if (ApplicationConfig.stage === Stage.Prod) {
            Clarity.init(ClarityInitializer.ClarityProjectId);
        }
    }
}
