import AppRoutes from '@/routes';
import { NotificationProvider, ThemeProvider } from '@/common/context';
import MobileMessage from './components/MobileMessage';
import { DeviceAnalyzer } from './client/device/DeviceAnalyzer';
import './assets/scss/Saas.scss';

const App = () => {
    const deviceAnalyzer = new DeviceAnalyzer();
    return (
        <ThemeProvider>
            <NotificationProvider>{deviceAnalyzer.isMobile() ? <MobileMessage /> : <AppRoutes />}</NotificationProvider>
        </ThemeProvider>
    );
};

export default App;
