import { ApplicationConfigType } from './applicationConfigType';
import { Stage } from '@/config/Stage.ts';

export const ApplicationConfig: ApplicationConfigType = {
    stage: Stage.Alpha,
    apiURL: 'https://alpha.apiv2.garam.26area.com',
    chatbotURL: 'https://alpha.chatbot.garam.26area.com',
    streamChatAPIKey: 'b43k55zm47j5',
    AWSConfigs: {
        Auth: {
            "aws_project_region": "us-west-2",
            // @ts-ignore
            "aws_cognito_region": "us-west-2",
            "aws_user_pools_id": "us-west-2_cziDW4PQZ",
            "aws_user_pools_web_client_id": "1heh6hou7f02b9bm5af0i6td2s",
            "oauth": {
                "domain": "area26userpool-dev.auth.us-west-2.amazoncognito.com",
                "scope": [
                    "aws.cognito.signin.user.admin",
                    "email",
                    "openid",
                    "phone",
                    "profile"
                ],
                "redirectSignIn": "http://localhost:3000/,https://alpha.dashboard.aieverywhere.26area.com/",
                "redirectSignOut": "http://localhost:3000/,https://alpha.dashboard.aieverywhere.26area.com/",
                "responseType": "code"
            },
            "federationTarget": "COGNITO_USER_POOLS",
            "aws_cognito_username_attributes": [
                "EMAIL"
            ],
            "aws_cognito_social_providers": [],
            "aws_cognito_signup_attributes": [],
            "aws_cognito_mfa_configuration": "OFF",
            "aws_cognito_mfa_types": [],
            "aws_cognito_password_protection_settings": {
                "passwordPolicyMinLength": 8,
                "passwordPolicyCharacters": [
                    "REQUIRES_LOWERCASE",
                    "REQUIRES_UPPERCASE",
                    "REQUIRES_NUMBERS",
                    "REQUIRES_SYMBOLS"
                ]
            },
            "aws_cognito_verification_mechanisms": [
                "EMAIL"
            ]
        }
    },
    stripe: {
        publishKey: 'pk_test_51PWnBqIge8EjM6ZeBNXFFoDURwuJIj0g917jDRpi0suroztsdPjoAoJQSt2JzqFxeX81JrLWRrbQVS7hqHGnKvg900jru84bA3',
    },
}