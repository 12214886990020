import { Route, Routes as ReactRoutes } from 'react-router-dom';
import { DemoChatBot } from '@/pages/demo/DemoChatBot.tsx';
import Register from '@/pages/register';
import { useEffect } from 'react';

export default function DemoRoutes() {
    useEffect(() => {
        const iframe = document.getElementById('chat-widget-iframe');
        if (iframe) {
            iframe.remove();
        }
    }, []);

    return (
        <ReactRoutes>
            <Route path="/register" element={<Register />} />
            <Route path="/chatBot" element={<DemoChatBot />} />
        </ReactRoutes>
    );
}
