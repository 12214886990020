import { useNotificationContext } from '@/common';
import { lazy, useLayoutEffect, useState } from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import VerticalLayout from '@/layouts/Vertical';
import { Home } from '@/pages/home';
// import Chatbot from '@/pages/chatbot';
import Inbox from '@/pages/inbox';
import Dashboard from '@/pages/dashboard';
import Billing from '@/pages/billing';
import AccountSetting from '@/pages/account';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { useAppContext } from '@/context/App.context';
import { useConversationsStatus } from '@/hooks/inbox/useConversationsStatus';
import { getNotification, getNotificationTitleByType } from '@/client/mappers/notificationMapper';
import Settings from '@/pages/settings';
import { ChatbotPlayground } from '@/pages/refactorChatbot';

/**
 * routes import
 */
const Error404Alt = lazy(() => import('../pages/otherpages/Error404Alt'));
const audio = new Audio('/new_message.mp3');

export default function ProtectedRoutes() {
    const notificationClient = ClientFactory.createNotificationClient();
    const { showNotification } = useNotificationContext();
    const { serviceIds } = useAppContext();
    const { refetch } = useConversationsStatus();
    const [notificationListenerRegistered, setNotificationListenerRegistered] = useState(false);

    useLayoutEffect(() => {
        if (serviceIds && serviceIds.serviceId && !notificationListenerRegistered) {
            notificationClient.listenNotification(serviceIds!.serviceId, (message) => {
                const { type, newMessage, conversationId } = getNotification(message.messageBody);
                showNotification({
                    title: getNotificationTitleByType(type),
                    message: newMessage,
                    type: 'info',
                    linkTo: {
                        to: `/inbox/conversations/detail?conversationId=${conversationId}`,
                        message: 'Go Conversation',
                    },
                });
                audio.play();
                refetch();
            });
            setNotificationListenerRegistered(true);
        }
    }, [audio, notificationClient, notificationListenerRegistered, refetch, serviceIds, showNotification]);

    return (
        <ReactRoutes>
            <Route path="/*" element={<VerticalLayout />}>
                <Route index element={<Home />} />
                <Route path="dashboard/*" element={<Dashboard />} />
                <Route path="inbox/*" element={<Inbox />} />
                <Route path="settings/*" element={<Settings />} />
                <Route path="billing/*" element={<Billing />} />
                <Route path="*" element={<Error404Alt />} />
                <Route path="account/*" element={<AccountSetting />} />
                <Route path="aiAssistant/playground" element={<ChatbotPlayground />} />
            </Route>
        </ReactRoutes>
    );
}
