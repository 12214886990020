import App from './App';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import { ContextProviderComponent } from './context/ContextProvider.component';
import '@aws-amplify/ui-react/styles.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from './client/api/Query.ts';
import '@/common/languages/i18n';
import { AmplifyInitializer } from './client/user/amplifyInitializer.ts';
import { AppContextProvider } from './context/App.context.tsx';
import { NotificationContextProvider } from './context/NotificationContext.tsx';
import { ClarityInitializer } from '@/client/browser/ClarityInitializer.ts';

AmplifyInitializer.initializeAmplifyWithBrowser();
ClarityInitializer.initClarity();

const container = document.getElementById('garam');
if (container) {
    const root = createRoot(container);
    root.render(
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ContextProviderComponent>
                <QueryClientProvider client={queryClient}>
                    <AppContextProvider>
                        <NotificationContextProvider>
                            <App />
                        </NotificationContextProvider>
                    </AppContextProvider>
                    {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
                </QueryClientProvider>
            </ContextProviderComponent>
        </BrowserRouter>,
    );
}
