import { TimeWindow } from '@/model/Application';

export function useOperationTimeslots() {
    function validateTimeWindow(timeWindow: TimeWindow): boolean {
        if (!timeWindow.startAt || !timeWindow.endAt) {
            return true;
        }

        return timeWindow.startAt.getTime() < timeWindow.endAt.getTime();
    }

    function changeOperationTimeSlot(operationTimeslots: TimeWindow[], index: number, timeWindow: TimeWindow): TimeWindow[] {
        const newOperationTimeSlots = [...operationTimeslots];
        newOperationTimeSlots[index] = timeWindow;

        return newOperationTimeSlots;
    }

    function deleteOperationTimeSlot(operationTimeslots: TimeWindow[], index: number): TimeWindow[] {
        const newOperationTimeSlots = [...operationTimeslots];
        newOperationTimeSlots.splice(index, 1);

        return newOperationTimeSlots;
    }

    function addOperationTimeSlot(operationTimeslots: TimeWindow[]): TimeWindow[] {
        return [...operationTimeslots, { startAt: undefined, endAt: undefined }];
    }

    function isAllValidTimeWindows(operationTimeslots: TimeWindow[]): boolean {
        return operationTimeslots.every((timeWindow) => timeWindow.startAt !== undefined && timeWindow.endAt !== undefined && validateTimeWindow(timeWindow));
    }

    return {
        validateTimeWindow,
        changeOperationTimeSlot,
        deleteOperationTimeSlot,
        addOperationTimeSlot,
        isAllValidTimeWindows,
    };
}
