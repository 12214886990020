import { ClientFactory } from '@/client/ClientFactory.ts';
import { useMessageNotificationContext } from '@/context/NotificationContext';
import { useAppContext } from '@/context/App.context';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export function useConversationsStatus() {
    const { serviceIds } = useAppContext();
    const { setUpdateConversationCount } = useMessageNotificationContext();
    const inboxClient = ClientFactory.createInboxClient();

    const { data, refetch } = useQuery({
        queryKey: ['Get', serviceIds],
        queryFn: () => inboxClient.getConversationsStatus(serviceIds.serviceId),
        enabled: !!serviceIds.serviceId,
    });

    useEffect(() => {
        if (data) {
            setUpdateConversationCount({
                open: data.nOfConversationOpened,
                closed: data.nOfConversationClosed,
            });
        }
    }, [data, setUpdateConversationCount]);

    return { refetch };
}
