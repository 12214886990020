import { Button, Row, Form, Col } from 'react-bootstrap';
import { Spinner } from '@/components';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNotificationContext } from '@/common/context/useNotificationContext';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DefaultIdIndex, useAppContext } from '@/context/App.context';
import { AgentScriptIntroduction } from './component/AgentScriptIntroduction.tsx';

export const AgentScript = () => {
    const { t } = useTranslation();
    const serviceClient = ClientFactory.createGaramServiceClient();
    const { serviceIds } = useAppContext();
    const { showNotification } = useNotificationContext();
    const [plainText, setPlainText] = useState<string>('');

    const { data } = useSuspenseQuery({
        queryKey: ['plainText2', 'Get'],
        queryFn: () => serviceClient.getPlainText(serviceIds.applicationIds[DefaultIdIndex]),
    });

    useEffect(() => {
        if (data) {
            setPlainText(data.context);
        }
    }, [data]);

    const { mutate: upsertPlainTexts, isPending } = useMutation({
        mutationFn: () => serviceClient.createPlainText(serviceIds.applicationIds[DefaultIdIndex], plainText),
        onSuccess: () => {
            showNotification({
                title: 'Success',
                message: t('MESSAGE_CREATE_PLAIN_TEXT_SUCCESS'),
                type: 'success',
            });
        },
        onError: () => {
            showNotification({
                title: 'Error',
                message: t('MESSAGE_CREATE_PLAIN_TEXT_FAIL'),
                type: 'error',
            });
        },
    });

    const onChangePlainText = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setPlainText(e.target.value);
    }, []);

    return (
        <>
            <Row>
                <Col xs={12}>
                    <AgentScriptIntroduction />
                    <Form.Group>
                        <Form.Label>{t('AGENT_SCRIPTS')}</Form.Label>
                        <Form.Control value={plainText} onChange={onChangePlainText} as={'textarea'} rows={13} />
                        <div className="d-flex justify-content-end">
                            <Button className="mt-2 ms-auto" type="submit" onClick={() => upsertPlainTexts()}>
                                {isPending && <Spinner className="spinner-border-sm me-1" tag="span" color="white" />}
                                {isPending ? `${t('LOADING')}...` : t('SUBMIT')}
                            </Button>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};
