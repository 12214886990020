import { InboxClient } from '@/client/chat/InboxClient.ts';
import { ChatSummary, Conversation } from '@/model/chat/Conversation';
import { Message } from '@/model/Message';
import { UserInfoClient } from '@/client/user/UserInfoClient.ts';
import { MessageResponse, StreamChat, UserResponse } from 'stream-chat';
import { SenderType } from '@/model/SenderType.ts';
import { Customer } from '@/model/Customer.ts';
import { ConversationsStatus, ConversationStatus } from '@/pages/inbox/types.ts';
import { Conversations } from '@/model/chat/Conversations.ts';

const GARAM_AGENT_ID = '26area';
export class StreamChatInboxClient implements InboxClient {
    private static readonly CHANNEL_TYPE = 'messaging';

    constructor(
        private readonly userInfoClient: UserInfoClient,
        private readonly chatClient: StreamChat,
        private readonly apiURL: string,
    ) { }

    public async connect(): Promise<void> {
        if (this.chatClient.user) {
            return;
        }

        const user = (await this.userInfoClient.getCurrentUser())!;

        const userToken = await this.getUserToken(user.id);

        await this.chatClient.connectUser(
            {
                id: user.id,
                name: user.givenName,
                role: 'admin',
            },
            userToken,
        );
    }

    public async disconnect(): Promise<void> {
        await this.chatClient.disconnectUser();
    }

    public async getConversation(serviceId: string, conversationId: string): Promise<Conversation> {
        return (
            await fetch(`${this.apiURL}/chat/conversation?serviceId=${serviceId}&conversationId=${conversationId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        ).json();
    }

    public async getConversations(serviceId: string, status: ConversationStatus, nextToken?: string): Promise<Conversations> {
        let conversationUrl = `${this.apiURL}/chat/conversations?serviceId=${serviceId}&status=${status}`;

        if (nextToken) {
            conversationUrl += `&conversationToken=${nextToken}`;
        }

        const conversations: Conversations = await (
            await fetch(conversationUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        ).json();

        return {
            conversations: conversations.conversations
                // TODO remove any
                .map((conversation: any) => {
                    return {
                        id: conversation.id,
                        applicationId: conversation.applicationId,
                        serviceId: conversation.serviceId,
                        enableLLM: conversation.enableLLM,
                        lastMessage: conversation.lastMessage,
                        lastMessageAt: new Date(conversation.lastMessageAt),
                        status: conversation.status,
                        customerId: conversation.customerId,
                        chatSummary: this.getOrElseChatSummary(conversation.customerId, conversation.chatSummary),
                    };
                })
                .sort((l, r) => r.lastMessageAt.getTime() - l.lastMessageAt.getTime()),
            nextToken: conversations.nextToken,
        };
    }

    private getOrElseChatSummary(customerId: string, chatSummary?: ChatSummary): ChatSummary {
        if (chatSummary) {
            return chatSummary;
        }

        if (customerId) {
            return {
                summary: `Customer#${customerId.substring(0, 4)}`,
                shortSummary: `Customer#${customerId.substring(0, 4)}`,
                customerInfo: new Map<string, string>(),
            };
        }

        return {
            summary: 'Unknown customer',
            shortSummary: 'Unknown customer',
            customerInfo: new Map<string, string>(),
        };
    }

    public async getConversationsStatus(serviceId: string): Promise<ConversationsStatus> {
        return (await fetch(`${this.apiURL}/conversations/status?serviceId=${serviceId}`)).json();
    }

    public async getMessages(conversationId: string): Promise<Message[]> {
        await this.connect();

        const channel = this.chatClient.channel(StreamChatInboxClient.CHANNEL_TYPE, conversationId);
        const state = await channel.watch();

        if (!Object.hasOwn(state.members, this.chatClient.user!.id)) {
            await channel.addMembers([this.chatClient.user!.id]);
        }

        const response = await channel.query({ messages: { limit: 100 } });

        return response.messages.map((message) => this.convertToMessage(message));
    }

    public async listenConversation(conversationId: string, callback: (message: Message) => void): Promise<void> {
        await this.connect();

        const channel = this.chatClient.channel(StreamChatInboxClient.CHANNEL_TYPE, conversationId);

        channel.on('message.new', (event) => {
            callback(this.convertToMessage(event.message!));
        });
    }

    public async sendMessage(conversationId: string, message: Message): Promise<void> {
        await this.connect();
        const channel = this.chatClient.channel(StreamChatInboxClient.CHANNEL_TYPE, conversationId);
        const state = await channel.watch();

        if (!Object.hasOwn(state.members, this.chatClient.user!.id)) {
            await channel.addMembers([this.chatClient.user!.id]);
        }

        await channel.sendMessage({ text: message.messageBody });
    }

    public async updateConversation(conversation: Conversation): Promise<Conversation> {
        const accessToken = await this.userInfoClient.getAccessToken();
        return (
            await (
                await fetch(`${this.apiURL}/chat/conversation`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(conversation),
                })
            ).json()
        ).conversation;
    }

    public async getCustomerInfo(conversationId: string): Promise<Customer> {
        const state = await this.chatClient.channel(StreamChatInboxClient.CHANNEL_TYPE, conversationId).watch();
        const member = state.members.filter((member) => {
            if (member.role === 'user') {
                return member.user;
            }
        });

        return { id: member[0].user!.id };
    }

    private convertToMessage(streamMessage: MessageResponse): Message {
        return {
            id: streamMessage.id,
            senderType: this.getSenderType(streamMessage.user!),
            messageBody: streamMessage.text!,
        };
    }

    private getSenderType(user: UserResponse): SenderType {
        if (user.role === 'user') {
            return SenderType.Customer;
        }

        if (user.role === 'admin' && user.id === GARAM_AGENT_ID) {
            return SenderType.Machine;
        }

        return SenderType.HumanAgent;
    }

    private async getUserToken(userId: string): Promise<string> {
        return await (
            await fetch(`${this.apiURL}/chat/token?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        ).text();
    }
}
