import { useEffect, useState } from 'react';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '@/context/App.context';
import { Application } from '@/model/Application.ts';
import { useNotificationContext } from '@/common';
import { useOperationTimeslots } from './useOperationTimeslots.ts';

export default function useUpdateChatbotConfiguration() {
    const [application, setApplication] = useState<Application>();
    const serviceClient = ClientFactory.createGaramServiceClient();
    const { serviceIds } = useAppContext();
    const { showNotification } = useNotificationContext();
    const { isAllValidTimeWindows } = useOperationTimeslots();

    const { data } = useQuery({
        queryKey: ['Application', 'Get'],
        queryFn: async () => serviceClient.getApplication(serviceIds!.serviceId),
    });

    useEffect(() => {
        if (data) {
            setApplication(data);
        }
    }, [data]);

    const handleUpdateChatbotConfiguration = async () => {
        if (serviceIds && isAllValidTimeWindows(application?.operationTimeSlots || [])) {
            try {
                await serviceClient.updateApplication(serviceIds!.serviceId, application!);
                showNotification({
                    title: 'Success',
                    message: 'Update AI Assistant Configuration Success',
                    type: 'success',
                });
            } catch (error) {
                showNotification({
                    title: 'Error',
                    message: 'Fail service success',
                    type: 'error',
                });
            }
        }
    };

    function changeApplication(updateFunc: (prev: Application) => Application): void {
        if (application) {
            setApplication((prev) => updateFunc(prev!));
        }
    }

    function isFormValid(): boolean {
        return application?.name !== '' && application?.description !== '' && application?.greetingMessage !== '' && isAllValidTimeWindows(application?.operationTimeSlots || []);
    }

    return {
        chatbotConfiguration: {
            applicationName: application?.name,
            applicationDescription: application?.description,
            responsibilities: application?.responsibilities,
            greetingMessage: application?.greetingMessage,
            operationTimeSlots: application?.operationTimeSlots,
        },
        handleUpdateChatbotConfiguration,
        changeApplication,
        isFormValid,
    };
}
